<template>
  <div class="orderlist bj freight-container">
    <div class="title">
      <div class="d-flex a-center">
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
        <div class="title-style">
          服务费结算合计：<span
            >{{
              tool.toDecimal2(
                (this.liquidate_amount_total * 10000) / 100 / 100 / 10000
              )
            }}元</span
          >
        </div>
      </div>
      <div class="seach">
        <div class="inputs">
          <el-date-picker
            @change="payChange"
            v-model="pay_at"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="结算日期时间起"
            end-placeholder="结算日期时间止"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.settle_batch_no"
            placeholder="请输入批次号"
            clearable
          ></el-input>
        </div>

        <div class="inputs multiple-select">
          <!-- <el-input v-model="from1.logisticsName" placeholder="请输入城市仓" clearable></el-input> -->
          <el-select
            @change="cityChange"
            filterable
            v-model="from1.store_logistics_id"
            clearable
            placeholder="选择城市仓"
          >
            <el-option
              v-for="item in storelist"
              :key="item.id"
              :label="item.name"
              :value="item.id.toString()"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs multiple-select">
          <!-- <el-input v-model="from1.deliveryName" placeholder="请输入自提点" clearable></el-input> -->
          <el-select
            v-model="from1.store_delivery_id"
            clearable
            placeholder="选择自提点"
          >
            <el-option
              v-for="item in selflist"
              :key="item.id"
              :label="item.name"
              :value="item.id.toString()"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.logistic_business_id"
            clearable
            placeholder="请选择集配中心"
          >
            <el-option
              v-for="item of logisticsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button
          :disabled="!tableData.length || disabledExport"
          @click="onHandleExport"
          type="warning"
          icon="el-icon-download"
          >导出列表</el-button
        >
        <el-button
          :disabled="!tableData.length || disabledDetailsExport"
          @click="onHandleExportDetails"
          type="warning"
          icon="el-icon-download"
          >导出明细</el-button
        >
      </div>
    </div>

    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        ref="multipleTable"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="order_no" align="center" label="结算批次号">
          <template slot-scope="scope">
            <span v-if="scope.row.seettle_batch">{{
              scope.row.seettle_batch.logistics_purchase_batch_no
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          align="center"
          label="结算时间"
          width="130"
        >
          <template slot-scope="scope">
            {{ $empty.time(scope.row.liquidate_time) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="logistic_business_name"
          align="center"
          label="集配中心"
        >
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.logistic_business_name) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="城市仓">
          <template slot-scope="scope">
            <span v-if="scope.row.logistics">{{
              scope.row.logistics.name
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="自提点">
          <template slot-scope="scope">
            <span v-if="scope.row.delivery">{{ scope.row.delivery.name }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="结算订单数">
          <template slot-scope="scope">
            {{ scope.row.order_number }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="服务费支付金额(元)">
          <template slot-scope="scope">
            {{
              tool.toDecimal2((scope.row.purchase_amount * 10000) / 100 / 10000)
            }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="售后退费金额(元)">
          <template slot-scope="scope">
            {{
              tool.toDecimal2(
                (scope.row.aftersale_amount * 10000) / 100 / 10000
              )
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="service_fee_amount"
          :label="serviceFeeLabel"
          align="center"
        ></el-table-column>
        <el-table-column prop="name" align="center" label="结算比例(%)">
          <template slot-scope="scope">
            {{ scope.row.liquidate_rate }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="结算金额(元)">
          <template slot-scope="scope">
            {{
              tool.toDecimal2(
                (scope.row.liquidate_amount * 10000) / 100 / 100 / 10000
              )
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <router-link
              :to="{ name: 'substituteDetail', query: { id: scope.row.id } }"
              class="main-color pointer text-none"
              >查看订单
            </router-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from1.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
// import { Loading } from "element-ui";
import { BASE } from "@/api";
import url from "url";
// import { getOffsetDate } from "@/utils/tools/date.js";
// import { postPurchaseSettleExport } from "@/api/export/center";

export default {
  name: "orderlist",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: true,
      pay_at: "",
      liquidate_amount_total: "",
      from: {
        store_logistics_id: "",
        store_delivery_id: "",
        settle_batch_no: "",
        create_start: "",
        create_end: "",
        page: 1,
        pageSize: 10,
        logistic_business_id: "",
      },
      from1: {
        store_logistics_id: "",
        store_delivery_id: "",
        settle_batch_no: "",
        create_start: "",
        create_end: "",
        page: 1,
        pageSize: 10,
        logistic_business_id: "",
      },
      tableData: [],
      currentPage: 1,
      total: 0,
      //列表页面不同功能参数
      authentication: [
        {
          id: -1,
          name: "全部",
        },
        {
          id: 0,
          name: "未认证",
        },
        {
          id: 1,
          name: "审核中",
        },
        {
          id: 2,
          name: "审核成功",
        },
        {
          id: 3,
          name: "审核失败",
        },
      ],
      options: [
        {
          value: "logistics",
          label: "集配",
        },
        {
          value: "delivery",
          label: "自提",
        },
      ],
      options1: [],
      storelist: [],
      selflist: [],
      logistics_id: "",
      list: [],
      list1: [],
      logisticsList: [],
      // 手续费比例文案
      serviceFeeLabel: "",
      disabledExport: false, // 导出禁用（导出列表）
      disabledDetailsExport: false, // 导出禁用（导出明细）
    };
  },
  created() {
    if (window.location.href.indexOf("?") > -1) {
      let url = this.tool.getUrl(window.location.href);
      this.from = url;
      this.from1 = url;
      this.currentPage = Number(this.from1.page);
      this.from1.pageSize = Number(this.from1.pageSize);
      this.from1.store_logistics_id = this.from1.store_logistics_id.toString();
      this.from1.store_delivery_id = this.from1.store_delivery_id.toString();
      this.from.store_logistics_id = this.from1.store_logistics_id + "";
      this.from.store_delivery_id = this.from1.store_delivery_id + "";
      console.log(this.from1);
      // 集配中心 回显
      this.from.logistic_business_id = this.from.logistic_business_id
        ? Number(this.from.logistic_business_id)
        : "";
      this.from1.logistic_business_id = this.from1.logistic_business_id
        ? Number(this.from1.logistic_business_id)
        : "";
      if (this.from1.store_logistics_id) {
        this.getListData(this.from1.store_logistics_id);
      }
      if (this.from1.create_start) {
        this.pay_at = [this.from1.create_start, this.from1.create_end];
      }
    }
    this.hqlist();
    this.citylist();
    this.getAjaxLogisticsList();
  },
  methods: {
    /**
     * 获取集配中心列表
     */
    async getAjaxLogisticsList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticsList = data;
        console.log("ajax getAjaxLogisticsList", data);
      } catch (err) {
        console.log("ajax getAjaxLogisticsList err", err);
      }
    },
    getListData(val) {
      this.$api.general
        .deliveryList({
          logistics_id: val, //必须填写
          page: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log(res, "获取数据");
          this.selflist = res.data.data;
        });
    },
    /**
     * 导出数据（下载中心）
     */
    async onHandleExport() {
      /** 旧导出逻辑 */
      // let form = this.tool.DeepCopy({}, this.from1);
      // window.open(
      //   BASE.PRO1 +
      //     `/purchase/settle/list/export?settle_batch_no=` +
      //     form.settle_batch_no +
      //     "&store_delivery_id=" +
      //     form.store_delivery_id +
      //     "&create_end=" +
      //     form.create_end +
      //     "&create_start=" +
      //     form.create_start +
      //     "&store_logistics_id=" +
      //     form.store_logistics_id +
      //     "&logistic_business_id=" +
      //     this.from1.logistic_business_id +
      //     "&token=" +
      //     sessionStorage.getItem("token"),
      //   "_blank"
      // );
      /** 旧接口，精简逻辑 */
      const query = { ...this.from1, token: sessionStorage.getItem("token") };
      delete query.page;
      delete query.pageSize;
      const params = url.format({ query });
      const path = BASE.PRO1 + "/purchase/settleNew/list/export";
      window.open(path + params);
      /** 新导出逻辑 */
      // this.disabledExport = true;
      // try {
      //   const query = {
      //     ...this.from1,
      //     token: sessionStorage.getItem("token"),
      //   };
      //   delete query.page;
      //   delete query.pageSize;
      //   await postPurchaseSettleExport(query);
      //   this.goExportCenter();
      // } catch (err) {
      //   console.log("ajax postPurchaseSettleExport err", err);
      // }
    },
    /**
     * 导出明细
     */
    onHandleExportDetails() {
      const query = { ...this.from1, token: sessionStorage.getItem("token") };
      delete query.page;
      delete query.pageSize;
      const params = url.format({ query });
      const path = BASE.PRO1 + "/purchase/settleNew/detail/export";
      window.open(path + params);
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    citylist() {
      this.$api.general
        .logisticsList({
          name: "",
          page: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log(res, "获取数据");
          this.storelist = res.data.data;
        });
    },
    cityChange(val) {
      this.from1.deliveryId = "";
      this.getListData(val);
    },
    search() {
      this.from.page = 1;
      this.from1.page = 1;
      this.currentPage = 1;
      this.from = this.tool.DeepCopy({}, this.from1);
      this.hqlist();
      this.tool.getResult(this.from, window.location.href);
    },
    changestore(e) {
      // console.log(e);
      this.ruleForm.name = JSON.parse(e).name;
      this.ruleForm.store_id = JSON.parse(e).id;
    },
    addclick() {
      console.log("新增");

      this.ruleForm = {
        business_type_code: "logistics", //集配：logistics 自提点：delivery
        store_id: "",
        name: "",
        itemdata: "",
        username: "",
        password: "",
      };

      this.centerDialogVisible = true;
      this.title = "新增中心账号";
    },
    tianku() {
      console.log(this.ruleForm);
      this.centerDialogVisible = false;
      this.$api.login.addbusiness(this.ruleForm).then((res) => {
        this.$message({
          type: "success",
          message: "添加成功",
        });
        this.Refresh();
      });
    },
    Refresh() {
      this.from = {
        store_logistics_id: "",
        store_delivery_id: "",
        settle_batch_no: "",
        create_start: "",
        create_end: "",
        page: 1,
        pageSize: 10,
        logistic_business_id: "",
      };
      this.from1 = {
        store_logistics_id: "",
        store_delivery_id: "",
        settle_batch_no: "",
        create_start: "",
        create_end: "",
        page: 1,
        pageSize: 10,
        logistic_business_id: "",
      };
      this.currentPage = 1;
      this.tool.getResult(this.from1, window.location.href);
      this.hqlist();
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.tool.getResult(this.from1, window.location.href);
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.tool.getResult(this.from1, window.location.href);
      this.hqlist();
    },
    payChange(val) {
      console.log(val);
      if (val) {
        this.from1.create_start = val[0];
        this.from1.create_end = val[1];
      } else {
        this.from1.create_start = "";
        this.from1.create_end = "";
      }
    },
    hqlist() {
      this.loading = true;
      this.$api.general.purchaseList(this.from).then((res) => {
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
        // const { service_fee_title } = res.data;
        // this.serviceFeeLabel = `平台服务费(${service_fee_title || "0.4%"})`;
        this.serviceFeeLabel = "金融手续费";
        this.liquidate_amount_total = res.data.liquidate_amount_total;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss">
.freight-container.orderlist {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .bjsearch {
    background: #333333;
    border-color: #333333;
    margin-right: 5px;
  }

  .bjsearch:active {
    background: #333333;
    border-color: #333333;
  }

  .checked-box {
    padding: 13px 15px;
  }

  .title {
    background: #ffffff;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    .title-style {
      color: #999999;
      span {
        color: #13ae67;
        font-size: 20px;
      }
    }
    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      flex-wrap: wrap;

      .inputs {
        // width: 207px;
        margin-right: 8px;
        margin-bottom: 5px;
      }

      .multiple-select {
        width: 250px;
      }
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }

  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;

    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }

    .imgs {
      display: flex;

      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;

        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }

        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }

    .disply {
      display: flex;
      margin-bottom: 22px;
    }

    .mr {
      margin-right: 31px;
    }

    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }

      .el-input {
        width: 302px;
      }
    }
  }

  .colors {
    color: #fa884c;
  }

  .times {
    margin-right: 8px;
  }

  // .el-picker-panel {
  //   left: 1245px !important;
  // }
}
</style>
